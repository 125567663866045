import { Trans } from '@lingui/macro';
import Button from 'components/atoms/Button/Button';
import { CardIconProps } from 'components/atoms/CardIcon/CardIcon.model';
import { IconFA } from 'components/atoms/IconFA/IconFA';
import Image from 'components/atoms/Image/Image';
import { CardInfoImageSection } from 'components/CardInfoImageSection/CardInfoImageSection';
import { CompanyWorkWithUsSection } from 'components/CompanyWorkWithUsSection/CompanyWorkWithUsSection';
import { Comparison } from 'components/Comparison/Comparison';
import { ContactFormSection } from 'components/ContactFormSection/ContactFormSection';
import { ContactFormSectionProps } from 'components/ContactFormSection/ContactFormSection.model';
import { ElevateDevelopmentGameSection } from 'components/ElevateDevelopmentGameSection/ElevateDevelopmentGameSection';
import { ElevateDevelopmentGameSectionProps } from 'components/ElevateDevelopmentGameSection/ElevateDevelopmentGameSection.model';
import { GreatPrideSection } from 'components/GreatPrideSection/GreatPrideSection';
import { GreatPrideSectionProps } from 'components/GreatPrideSection/GreatPrideSection.model';
import { HeaderContentModel } from 'components/Header/Header.model';
import Layout from 'components/Layout';
import { OurHistoryVision } from 'components/OurHistoryVision/OurHistoryVision';
import { OurHistoryVisionProps } from 'components/OurHistoryVision/OurHistoryVision.model';
import Seo from 'components/SEO';
import { SustainableGoalsSection } from 'components/SustainableGoalsSection/SustainableGoalsSection';
import { SustainableGoalsSectionProps } from 'components/SustainableGoalsSection/SustainableGoalsSection.model';
import { ThankYouSection } from 'components/ThankYouSection/ThankYouSection';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { featureLists } from 'src/page-components/pricing/pricingCardsData';

import PricingSection from 'src/page-components/pricing/PricingSection';
import { PricingSectionProps } from 'src/page-components/pricing/PricingSection.model';

const FractalCloudVsTerraform = () => {
  const [thankYouSectionSubmitted, setThankYouSectionSubmitted] = useState(false);

  const elevateDevelopmentGame: ElevateDevelopmentGameSectionProps = {
    id: 'product',
    title: 'Looking to elevate your development game?',
    subtitle:
      'Revolutionize your software development with our secure, flexible, and collaborative platform.',
    cardIcons: [
      {
        iconName: 'elevate-development-game-section/cost-reduction',
        title: '30% Cost Reduction',
        subTitle:
          'Streamline development workflows and eliminates bottlenecks, resulting in significant infrastructure cost savings for enterprises.',
      },
      {
        iconName: 'elevate-development-game-section/infrastructure-code',
        title: '10x Infrastructure Code Reduction',
        subTitle:
          'Writing and maintaining infrastructure as code is simplified, freeing up valuable developer time and resources.',
      },
      {
        iconName: 'elevate-development-game-section/multi-cloud',
        title: 'Multi-Cloud Support',
        subTitle:
          'Enterprises can leverage the power of multiple cloud vendors, including GCP, Azure, AWS, and private Kubernetes setups.',
      },
      {
        iconName: 'elevate-development-game-section/cloud-service',
        title: 'Full Spectrum Cloud Services',
        subTitle:
          'Fractal Cloud supports FaaS, PaaS, CaaS, and IaaS at an agnostic level, making it easy for enterprises to adopt cloud-native solutions.',
      },
    ],
    ctaLabel: 'Discover Fractal Cloud',
  };

  const pricing: PricingSectionProps = {
    title: 'Simple, transparent pricing',
    featureLists: featureLists,
    requestQuoteUrl: '#contact-us',
    targetBlank: false,

    expandCloseCards: {
      expandCardsLabel: 'see full plan',
      closeCardsLabel: 'hide full plan',
    },
  };

  const headersComparison = ['Terraform', 'Fractal'];

  const rowsComparison = [
    { title: 'Support for multiple clouds', values: [true, true] },
    { title: 'No Domain Specific Language', values: [false, true] },
    { title: 'No specialized Cloud Infrastructure Staff required', values: [false, true] },
    { title: 'Multi-cloud Templates', values: [false, true] },
    { title: 'Hardened Components', values: [false, true] },
    { title: 'Build and expose custom infrastructure operations', values: [false, true] },
    { title: 'Automated components update', values: [false, true] },
    { title: 'Enterprise ready', values: [false, true] },
    { title: 'Multi-cloud Native', values: [false, true] },
  ];

  const imageMapping = {
    Terraform: 'companies/terraform-logo',
    Pulumi: 'companies/pulumi-logo',
    Humanitech: 'companies/humanitec-logo',
    Fractal: 'companies/fractal-logo',
  };

  const headerContent: HeaderContentModel[] = [
    {
      name: 'Product',
      url: '#product',
    },
    {
      name: 'Compare',
      url: '#compare',
    },
    {
      name: 'About Us',
      url: '#about-us',
    },
    {
      name: 'Pricing',
      url: '#pricing',
    },
    {
      name: 'Contact us',
      url: '#contact-us',
      type: 'contact',
    },
  ];

  const cardIconsLiveSystem: CardIconProps[] = [
    {
      title: 'Faster Deployments',
      subTitle:
        'Deploy infrastructure with minimal code, accelerating time-to-market and iterations.',
      iconName: 'live-system-first-section/faster-deployments',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Built-In Security',
      subTitle:
        'Pre-configured, security-hardened components ensure secure environments from the start.',
      iconName: 'live-system-first-section/built-in-security',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Reusable Blueprints',
      subTitle:
        'Extract and share Blueprints across teams, making scaling and collaboration seamless.',
      iconName: 'live-system-first-section/reusable-blueprints',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const cardIconsFractal: CardIconProps[] = [
    {
      title: 'Simplifies Cloud Complexity',
      subTitle: 'Use reusable Blueprints to manage Cloud-Native complexity effortlessly.',
      iconName: 'fractal-first-section/simplifies-cloud-complexity',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Compliance by Design',
      subTitle: 'Versioned Blueprints maintain compliance automatically as systems evolve.',
      iconName: 'fractal-first-section/compliance-by-design',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Effortless Multi-Cloud',
      subTitle:
        'Instantly deploy Blueprints across any cloud vendor with minimal configuration needed.',
      iconName: 'fractal-first-section/effortless-multi-cloud',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const isContactFormSubmitted = () => {
    setThankYouSectionSubmitted(true);
  };

  const contactForm: ContactFormSectionProps = {
    id: 'contact-us',
    isSubmitted: isContactFormSubmitted,
    title: 'Contact us now',
    subtitle: 'Enter your deatils to request information or a demo',
    form: {
      name: {
        label: 'Name',
        placeholder: 'Enter name',
        requiredLabel: 'Name is required',
      },
      company: {
        label: 'Company',
        placeholder: 'Enter company name',
        requiredLabel: 'Company is required',
      },
      phone: {
        label: 'Phone Number',
        placeholder: 'Enter number',
        requiredLabel: 'Phone number is required',
      },
      email: {
        label: 'Company e-mail',
        placeholder: 'Enter e-mail',
        requiredLabel: 'Company email is required',
        invalidLabel: 'Invalid email format',
      },
      message: {
        label: 'Message',
        placeholder: 'Enter message',
        requiredLabel: 'Message is required',
      },
    },
    submittingLabels: {
      send: 'Send Message',
      sending: 'Sending...',
      failed: 'Oh no! Something went wrong! Please refresh the page and try again',
      submitSuccessTitle: 'Thank you for reaching out!',
      submitSuccessSubTitle: 'You will get a response as soon as possible!',
    },
  };

  const greatPride: GreatPrideSectionProps = {
    title: 'We take great pride in',
    cardIcons: [
      {
        iconName: 'great-pride-section/legacy-competitive',
        title: 'Turning Legacy into Competitive Advantage',
        subTitle:
          'Revolutionize Mainframe ERP systems into lightning-fast event sources, streaming real-time data. Empower cloud microservices, eliminate overnight processes. Unleash the power of legacy systems for business growth.',
      },
      {
        iconName: 'great-pride-section/revenue-streams',
        title: 'Creating New Revenue Streams',
        subTitle:
          "Seize innovation's potential. We've reimagined on-prem products as scalable SaaS solutions, fueling new revenue streams for our clients. Unlock hidden potential and propel your business to new heights with our expert guidance.",
      },
      {
        iconName: 'great-pride-section/customer-succeed',
        title: 'Helping Our Customers Succeed',
        subTitle:
          "Fuel your growth with comprehensive solutions: Cloud Native transformations, automated infrastructure, security, training, and more. Conquer the cloud landscape, achieve efficiency, elevate your success. Let's reach unprecedented heights together.",
      },
    ],
  };

  const ourHistoryVision: OurHistoryVisionProps = {
    id: 'about-us',
    title: 'Our History and Vision',
    subtitle: 'From Sicily to Global Innovation',
    text: 'YanchWare is a multinational company with offices in Italy and Denmark, specializing in Cloud Computing and Cybersecurity. Our diverse, international team consists of developers, visual designers and marketers, all working together to deliver cutting-edge solutions.',
    secondText:
      'We are committed to providing businesses with the tools, services, and training they need to achieve their goals and thrive independently in today’s dynamic business landscape. At YanchWare, our journey is as unique as the innovations we bring to the cloud computing industry.',
  };

  const sustainableGoals: SustainableGoalsSectionProps = {
    title: 'Fractal Cloud: the best Internal Developer Platform',
    subtitle:
      'Fractal Cloud revolutionizes the future of technology, providing developers, businesses, and communities with the tools to lead change',
    cardIcons: [
      {
        iconName: 'sustainable-goals-section/quality-education',
        title: 'Your development team',
        subTitle:
          'Manages the infrastructure required for applications independently. Simple governance without complexity or specialized resources.',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
      {
        iconName: 'sustainable-goals-section/economical-growth',
        title: 'Your company',
        subTitle:
          'Regains decision-making control and freely chooses suppliers. Granular control without expensive in-house platforms.',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
      {
        iconName: 'sustainable-goals-section/sustainable-comunities',
        title: 'Centers of Excellence',
        subTitle:
          'Focus on real challenges without being constrained by predefined technologies. Prioritize business value and speed of execution.',
        className: 'flex flex-col items-center px-4 shadow-cardNew text-center',
        iconClassName: 'w-16 p-4',
      },
    ],
  };

  return (
    <Layout
      className="fractal-page"
      contactForm={false}
      header={{ isVisible: true, customHeader: headerContent }}
    >
      <Seo title="Fractal Cloud vs Terraform" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="hero-wrapper">
        <div className="flex flex-col justify-center items-center pt-40 m-auto h-[35rem] md:h-auto">
          <div className="text-white text-center px-9 mb-6 md:mb-4">
            <h1 className="text-4xl mb-6 md:mb-4">
              <Trans>Fractal Cloud</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                The best Internal Developer Platform that supports the entire software development
                lifecycle.
              </Trans>
            </p>
          </div>

          <div className="relative w-full hidden md:flex justify-center h-[29rem]">
            <Image name="fractal-vs-terraform/hero-image" className="max-w-[100rem]" />
            <div className="absolute inset-0 bg-gradient-to-t from-[#1e1374] to-transparent h-full" />
            <div className="absolute bottom-20">
              <a href="#pricing_cards">
                <Button kind="Primary" className="w-full text-nowrap">
                  <div className="flex items-center gap-3">
                    <span>Get Started</span>
                    <IconFA icon="arrow-right" className="w-4" />
                  </div>
                </Button>
              </a>
            </div>
          </div>

          <div className="block md:hidden">
            <a href="#pricing_cards">
              <Button kind="Primary" className="w-full text-nowrap">
                <div className="flex items-center gap-3">
                  <span>Get Started</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>

      <ElevateDevelopmentGameSection {...elevateDevelopmentGame} />
      <Comparison
        id="compare"
        title="Features Battle"
        subtitle="Which platform offers the best features for efficient software development? You make the choice."
        headers={headersComparison}
        showHeaderTitle={false}
        rows={rowsComparison}
        imageMapping={imageMapping}
      />

      <CompanyWorkWithUsSection title="Companies that trusted us" />

      <div id="pricing">
        <PricingSection {...pricing} />
      </div>

      {thankYouSectionSubmitted ? (
        <ThankYouSection
          title="Thank you for reaching out!"
          subtitle="You will get a response as soon as possible!"
        />
      ) : (
        <ContactFormSection {...contactForm} />
      )}

      <section className="mt-40 m-8">
        <div className="text-center mb-6">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            Thought and built for Enterprise Scale
          </h2>

          <div className="flex justify-center">
            <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
              Two approaches available to support your organization in any situation
            </p>
          </div>
        </div>
        <CardInfoImageSection
          title="Live-System First"
          subtitle="Rapid Roll-Outs Focused on End Systems, Ideal for PoCs and Moonshot Projects"
          cardIcons={cardIconsLiveSystem}
          className="flex flex-col-reverse lg:flex-row"
          imageName="live-system-first-section/live-system-image"
        />
        <CardInfoImageSection
          title="Fractal First"
          subtitle="Reusability, Automation, and Enterprise-Level Cloud Governance for Your Organization"
          cardIcons={cardIconsFractal}
          className="flex flex-col-reverse lg:flex-row-reverse"
          imageName="fractal-first-section/fractal-first-image"
          sectionClassName="mt-40"
        />
      </section>

      <section className="flex flex-col items-center justify-center max-w-[70rem] h-fit m-auto mt-64">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-8">
          <Trans>Our Certifications</Trans>
        </h2>

        <div className="w-full flex flex-col md:flex-row gap-6 items-center justify-between">
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Microsoft" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Google_Cloud" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/AWS" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Oracle" />
          </div>
        </div>
      </section>

      <GreatPrideSection {...greatPride} />
      <OurHistoryVision {...ourHistoryVision} />
      <SustainableGoalsSection {...sustainableGoals} />

      <section className="flex flex-col justify-center items-center my-64 m-auto px-9">
        <div className="text-center">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-4">
            <Trans>Join the Cloud Revolution</Trans>
          </h2>
          <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[80rem] mb-6">
            Are you ready to embark on a transformative journey?
          </p>
          <p className="text-[16px] font-[400] leading-[175%] max-w-[50rem] mb-8">
            Let us be your guide to success—where passion meets technology, and possibilities become
            realities. Together, we'll unleash the true power of cloud computing, fuel innovation,
            and empower individuals and organizations worldwide.
          </p>
          <div className="flex justify-center">
            <a href="#contact-us">
              <Button kind="Primary" className=" text-nowrap py-">
                <div className="flex items-center gap-3">
                  <span>Get in touch</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default FractalCloudVsTerraform;
